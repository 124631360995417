<template>
  <v-data-table
    :headers="headers"
    :items="jobWelcome">
    <template #[`item.telType`]="{ item }">
      <v-chip
        small
        v-if="item.telType === 'T'">
        {{ item.telType === 'T' ? 'POSTPAID' : 'PREPAID' }}
      </v-chip>
      <v-chip
        small
        v-else>
        ALL
      </v-chip>
    </template>
    <template #[`item.createTime`]="{ item }">
      {{ item.createTime | formatDate }}
    </template>
    <template #[`item.activated`]="{ item }">
      <v-switch
        dense
        v-model="item.activated"
        inset
        @change="changeStatus(item._id)"></v-switch>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'WelcomeJob',
  props: {
    jobWelcome: {
      type: Array,
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Job Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Title',
        align: 'start',
        value: 'titleEn'
      },

      {
        text: 'Tel Type',
        align: 'start',
        value: 'telType'
      },
      {
        text: 'Create Date',
        align: 'center',
        sortable: true,
        value: 'createTime'
      },
      {
        text: 'Job Status',
        align: 'center',
        sortable: true,
        value: 'status'
      },
      {
        text: 'Job Activated',
        align: 'center',
        value: 'activated'
      },
    ],
    cancelJob: {
      id: '',
      reason: '',
    },
  }),

  methods: {
    async changeStatus(jobId) {
      this.cancelJob.id = jobId;
      this.cancelJob.reason = 'Disable job.'
      await this.$store.dispatch('jobManagement/DELETE_JOB_WELCOME_BY_ID', this.cancelJob);
    }
  }
}
</script>